$primary-color: #00AFD3;
$secondary-color: #2E2D2C;
$grey-color: #444444;

:root {
  --primary-color: #00AFD3;
  --background-color: #ffffff;
}

$primary-color: var(--primary-color);
$secondary-color: #92DCE5;
$bg-color: var(--background-color);
$blue-color: #00D3E4;
$grey-color: #444444;