@import '../../../variables.scss';

.left-panel {
  flex: 1 0 320px;  
  background-color: white;

  .user-img {
    height: 180px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .control-div {
    h5 {
      font-size: 1.6em;
      font-weight: 700;
    }
    p {
      font-size: 1em;
    }
  }

  .chatting-info {
    flex: 1 1 auto;
    overflow: hidden;
    border-top: 2px solid #F5F5F5;
    position: relative;

    .chatting-history {
      flex: 1 1 auto;
      overflow-y: auto;
      padding: 0 0 150px 0;  
      position: relative;

      .chating-text-box {
        font-size: 0.7em;
        border-bottom: 2px solid #F5F5F5;
        
        &:last-child {
          border-bottom: 0;
        }

        h5 {
          font-weight: 700;
          font-size: 1rem;
        }
      }
    }
    
    .last-chat {
      position: absolute;
      bottom: 0.5rem;
      left: 1.5rem;
      width: calc(100% - 3.5rem);
      background: white;

      textarea{
        width: 100%;
        height: 100px;
        background: #F5F5F5;
        border: 1px solid #F5F5F5;
        outline-style: none;
        color: #2E2D2C;
        flex: 0 0 100px;
        overflow-y: auto;
        font-size: 14px;
        padding: 9px 8px;  
        resize: none;
        font-weight: 600;

        &::placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #2E2D2C;
        }  
      }
    }
  }

  @media (max-width: 991px) {
    flex: 1 0 240px;

    .user-img {
      height: 130px;
    }

    .control-div {
      h5 {
        font-size: 1.2rem;
      }

      p {
        font-size: 0.9rem;
      }

      img {
        width: 30px;
      }
    }
  }
}
