@import '../../variables.scss';

.choose-tour-modal {
  .modal-dialog {
    max-width: 600px;
  
    .modal-content {
      background-color: $primary-color;
      border: none;
      border-radius: 0.8rem;
  
      .modal-header {
        border-bottom: none;
        padding-top: 2rem;
        text-align: center;
  
        h1 {
          width: 100%;
          font-size: 1.5rem;
          font-weight: 700;
        }
  
        h2 {
          width: 100%;
          color: white;
          font-weight: 700;
          font-size: 1.5rem;
        }
      }
      .modal-body {
        padding: 20px 150px;
  
        h1 {
          color: white;
          text-align: center;
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .tour-list {
          height: 250px;
          padding: 1rem 1.5rem;
          border-radius: 0.5rem;
      
          ul {
            padding: 0;
            margin: 0;
            height: 100%;
            list-style: none;
            overflow: auto;

            li {
              line-height: 2;
              cursor: pointer;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
  
        Button {
          background: white;
          width: 100%;
          color: orange;
          margin-top: 0;
          border: 1rem;
          height: 50px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 0.9rem;
        }        
      }
    }
  }
}
