@import '../../../variables.scss';

.tour-card {
  text-align: center;
  padding-bottom: 5rem;
  position: relative;

  h1 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 2rem;;
  }

  p {
    font-size: 0.8rem;
  }

  a {
    color: $primary-color;
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  img {
    height: 200px;
  }

  .group-session-request-button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    img {
      width: 50px;
      height: 50px;
    }
  }
}