.side-panel {
  width: 80px;
  margin: 0 0.5rem;
  background-color: white;
  padding-top: 4rem;
}

@media (max-width: 991px) {
  .side-panel {
    width: 60px;
  }
}