@import '../../variables.scss';

#burgess-nav {
  height: 70px;

  @media (max-width: 991px) {
    height: 60px;
  }
}

#navbar-bugress {
  width: 100%;
  justify-content: space-between;
  background: white;
  z-index: 1;

  .nav-item {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
  }
  .bugres-brand {
    a {
      color: $primary-color;
      font-size: 37px;  
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .navbar-nav {
    align-items: center;
    color: $primary-color;
    font-weight: 700;

    .nav-link {
      font-size: 14px;
      font-weight: 600;

      &.logout {
        color: $primary-color;
      }
    }
  }
}