@import '../../../variables.scss';

.right-panel {
  flex: 1 1 100%;
  overflow: hidden;

  .main-header {
    height: 180px;

    .title {
      font-size: 4rem;    
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
    }
    
    .description {
      font-size: 1.3rem;
      font-weight: normal;
    }

    .dropleft {
      text-align: center;
    }
  }

  .tap-start-div {
    // background: url('./../../../assets/images/left-back1.png') no-repeat center center;
    background-size: 100% 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;

    h2 {  
      text-align: center;
      font-size: 40px;
      font-weight: 200;
    }
  }

  .btn-container {
    .btn-bugress-outline {
      flex: 0 1 33.33%;
      height: 55px;
      font-size: 1.2rem;

      a {
        color: #A3A1A1;

        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }

    .btn-bugress-outline.clickable {
      background: transparent;
      color: #A3A1A1;
      border-color: $primary-color;
    }

    
    .btn-bugress-outline.active {
      background-color: $primary-color !important;
      color: white !important;
      border-color: $primary-color !important;
    }

    .btn-bugress-outline.stop {
      background-color: transparent !important;
      color: #FF0050 !important;
      border-color: #FF0050 !important;
    }
  }

  @media screen and (max-width: 991px) {
    .main-header {
      height: 130px;

      .title {
        font-size: 2.5rem;
      }

      .description {
        font-size: 1rem;
      }
    }

    .btn-container .btn-bugress-outline {
      font-size: 1rem;
      height: 45px;
    }
  }
}