@import '../../../variables.scss';

.editorial {
  padding: 5rem 0;
  background-color: #f5f5f5;

  h1.title {
    width: 100%;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;

    &::after {
      content: '';
      background-color: $primary-color;
      width: 80px;
      height: 8px;
      display: block;
      margin: 1rem auto;
    }
  }

  .editorial-panel {
    height: 100%;
    background-color: white;

    img {
      width: 100%;
    }

    .panel-info {      
      button {
        border: none;
        outline: none;
        font-size: 0.8rem;
        padding: 0.3rem 1rem;
        border-radius: 0.1rem;
      }

      h1 {
        font-size: 1.3rem;
        text-align: center;
        font-weight: 700;
        margin-top: 1rem;
      }

      p {
        font-size: 0.8rem;
        text-align: center;
      }

      a {
        color: $primary-color;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }

  .left {
    margin-top: 30px;
  }

  .more-btn {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    font-size: 0.9rem;
    font-weight: 600;
  }
}